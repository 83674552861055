import React from 'react';
import { render } from 'react-dom';
import I18n from 'i18n-js';
import * as Sentry from '@sentry/react';
import { getLocaleFromBrowserLanguage } from 'grab-locale';
import Storage from 'grab-storage';
import { parse } from 'query-string';
import 'abortcontroller-polyfill';

import App from './components/App';
import './index.less';

window.Grab = {
  config: {},
  router: {},
  storage: Storage,
};

const defaultLocale = 'en';

// get language from url param
const { lang = '' } = parse(location.search);
// handle en-TH or en_TH lang format
const separator = lang.indexOf('-') !== -1 ? '-' : '_';
const langCode = lang.split(separator)[0];

const LOCALES = ['zh', 'en', 'id', 'km', 'ms', 'th', 'vi'];
const locale =
  langCode ||
  getLocaleFromBrowserLanguage({
    defaultLocale,
    availableLocales: LOCALES,
    navigator: window.navigator,
  });

localStorage.setItem('lang-code', locale);

const renderApp = () => {
  render(<App />, document.getElementById('root'));
};

window
  .fetch(require('config.json'))
  .then(async (configResponse) => {
    if (configResponse.status !== 200) return;
    window.Grab.config = await configResponse.json();
    let extraConfig = {};
    if (window.Grab.config.env === 'staging') {
      extraConfig = await require('./config/staging.json');
    } else if (window.Grab.config.env === 'production') {
      extraConfig = await require('./config/production.json');
    }
    window.Grab.config = { ...window.Grab.config, ...extraConfig };

    const project = window.location.pathname.startsWith('/business')
      ? 'pax-app'
      : 'mex-app';
    window
      .fetch(require(`public/locales/${locale}-${project}.json`))
      .then(async (localeResponse) => {
        if (localeResponse.status !== 200) return;
        I18n.translations[locale] = await localeResponse.json();
        I18n.locale = locale;
        if (window.Grab.config.sentryDsn) {
          Sentry.init({
            dsn: window.Grab.config.sentryDsn,
            environment: window.Grab.config.env,
            release: `mex-mobile-web@${process.env.APP_VERSION}`,
          });
        }
        renderApp();
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.error(err);
      });
  })
  .catch((err) => {
    Sentry.captureException(err);
    console.error(err);
  });

if (module.hot) {
  module.hot.accept('./components/App', () => {
    renderApp();
  });
}
