import React, { useState, useRef, useEffect, useCallback } from 'react';
import Lottie from 'lottie-react';
import { get } from 'lodash';
import silence from '../../public/audio/silence.mp3';
import ProgressBar from './ProgressBar';
import {
  callFinishedHandler,
  callLastSeenHandler,
  MessageType,
  NATIVE_ACTIONS,
} from './InsightsReelsHelper';
import './InsightsReels.less';

// TO TEST, post this message in console:
/*
window.postMessage({
  type: "data_anim",
  payload: JSON.stringify({
    animations: [{
        animationData: JSON.parse(localStorage.getItem("lottie1")),
      },
      {
        animationData: JSON.parse(localStorage.getItem("lottie2")),
      }
    ],
    musicUrl: "https://cdn1.suno.ai/977d1052-2e3f-460d-89da-6dd2f8c6758c.mp3"
  })
})
*/
// variable lottie - should contain the actual lottie animation data

// Player component for handling animations and audio
const LottiePlayer = () => {
  // State variables
  const [currentAnimation, setCurrentAnimation] = useState(0);
  const [animationData, setAnimationData] = useState(null);
  const [progress, setProgress] = useState(0);

  // Refs for accessing DOM elements
  const lottieRef = useRef(null);
  const musicRef = useRef(null);
  const voiceOverRef = useRef(null);
  const containerRef = useRef(null);

  // Handle touch events for navigation
  const handlePrevious = useCallback(
    () => {
      if (currentAnimation > 0) {
        callLastSeenHandler(currentAnimation, voiceOverRef.current.currentTime);
        setCurrentAnimation(currentAnimation - 1);
        setProgress(0);
        if (voiceOverRef.current) {
          voiceOverRef.current.play().catch(_ => {
            // this is an edge case and happens when the user touches multiple times when the file was still loading
            // if we don't catch the error, it will just report to sentry as an exception and eat up all the quota
          });
        }
      }
    },
    [currentAnimation, voiceOverRef],
  );

  const handleNext = useCallback(
    () => {
      if (
        animationData &&
        currentAnimation < animationData.animations.length - 1
      ) {
        callLastSeenHandler(currentAnimation, voiceOverRef.current.currentTime);
        setCurrentAnimation(currentAnimation + 1);
        setProgress(0);
        if (voiceOverRef.current) {
          voiceOverRef.current.play().catch(_ => {
            // same reason as above
          });
        }
      } else {
        callFinishedHandler();
      }
    },
    [animationData, currentAnimation, voiceOverRef],
  );

  const handlePause = useCallback(
    () => {
      if (voiceOverRef.current) {
        voiceOverRef.current.pause();
      }
      if (musicRef.current) {
        musicRef.current.pause();
      }
      if (lottieRef.current) {
        lottieRef.current.pause();
      }
    },
    [animationData, currentAnimation, voiceOverRef, musicRef, lottieRef],
  );

  const handlePlay = useCallback(
    () => {
      if (voiceOverRef.current) {
        voiceOverRef.current.play().catch(_ => {
          // same reason as above
        });
      }
      if (musicRef.current) {
        musicRef.current.play().catch(_ => {
          // same reason as above
        });
      }
      if (lottieRef.current) {
        lottieRef.current.play();
      }
    },
    [animationData, currentAnimation, voiceOverRef, musicRef, lottieRef],
  );

  // Handle incoming messages
  const handleMessage = useCallback(
    event => {
      if (event.data && event.data.type) {
        switch (event.data.type) {
          case MessageType.DATA_ANIM: {
            const receivedData = event.data.payload;
            try {
              const parsedData = JSON.parse(receivedData);
              setAnimationData(parsedData);
              setCurrentAnimation(parsedData.startIndex || 0);
              if (musicRef.current) {
                musicRef.current.play().catch(_ => {
                  // same reason as above
                });
              }
              if (voiceOverRef.current) {
                voiceOverRef.current.play().catch(_ => {
                  // same reason as above
                });
              }
            } catch (e) {
              setAnimationData(null);
              console.error('Error parsing animation data', e);
            }
            break;
          }
          case MessageType.NEXT_REEL: {
            handleNext();
            break;
          }
          case MessageType.PREV_REEL: {
            handlePrevious();
            break;
          }
          case MessageType.HOLD_REEL: {
            handlePause();
            break;
          }
          case MessageType.PLAY_REEL: {
            handlePlay();
            break;
          }
          default:
            console.log('Unknown message type', event.data.type);
            break;
        }
      }
    },
    [handleNext, handlePrevious, handlePause, handlePlay],
  );

  // Update progress of the current animation
  const updateProgress = () => {
    if (voiceOverRef.current && voiceOverRef.current.duration) {
      const { currentTime, duration } = voiceOverRef.current;
      setProgress(currentTime / duration * 100);
    }
  };

  // Set up event listener for messages
  useEffect(
    () => {
      window.addEventListener('message', handleMessage);
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    },
    [handleMessage],
  );

  // Get current animation data
  const currentAnimationData = get(
    animationData,
    ['animations', currentAnimation],
    null,
  );

  // Adjust music volume based on current animation
  useEffect(
    () => {
      if (musicRef && musicRef.current) {
        musicRef.current.volume = get(currentAnimationData, ['audioUrl'], null)
          ? 0.1
          : 0.3;
      }
    },
    [currentAnimation, animationData],
  );

  // Notify Flutter of index change
  useEffect(
    () => {
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview.callHandler(
          NATIVE_ACTIONS.INDEX_CHANGE,
          currentAnimation,
        );
      }
    },
    [currentAnimation],
  );

  // Set initial music volume
  useEffect(() => {
    if (musicRef && musicRef.current) {
      musicRef.current.volume = 0.3;
    }
  }, []);

  // Set up event listeners for voice over audio
  useEffect(
    () => {
      if (voiceOverRef && voiceOverRef.current) {
        voiceOverRef.current.load();
        voiceOverRef.current.addEventListener('ended', handleNext);
        voiceOverRef.current.addEventListener('timeupdate', updateProgress);
      }
      return () => {
        if (voiceOverRef && voiceOverRef.current) {
          voiceOverRef.current.removeEventListener('ended', handleNext);
          voiceOverRef.current.removeEventListener(
            'timeupdate',
            updateProgress,
          );
        }
      };
    },
    [animationData, currentAnimation, voiceOverRef],
  );

  return (
    <div
      data-testid="lottie-player-container"
      ref={containerRef}
      style={{
        position: 'relative',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#000000',
      }}
    >
      <audio ref={musicRef} src={get(animationData, 'musicUrl')}>
        <track kind="captions" />
      </audio>
      {currentAnimationData && (
        <div style={{ width: '100%', height: '100%' }}>
          <audio
            ref={voiceOverRef}
            src={
              currentAnimationData.audioUrl
                ? currentAnimationData.audioUrl
                : silence
            }
          >
            <track kind="captions" />
          </audio>
          <Lottie
            data-testid="lottie-player"
            lottieRef={lottieRef}
            animationData={currentAnimationData.animationData}
            loop={currentAnimationData.repeat !== false}
            autoplay
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        </div>
      )}
      <ProgressBar
        progress={progress}
        animCount={get(animationData, ['animations', 'length'], 0)}
        currentIndex={currentAnimation}
        safeAreaPadding={get(animationData, ['safeAreaPadding']) || 0}
      />
    </div>
  );
};

export default LottiePlayer;
